import { useCallback, useEffect, useState } from 'react'
import ReactJson from 'react-json-view'
import { connect, useSelector } from 'react-redux'
import {
	createOrUpdateCertificate,
	createStatement,
	deleteCertificateForm,
	downloadReleaseCertStatement,
	updateCertificateStatus,
	uploadStatement,
	validateSubjectForCertificate,
} from 'actions/certificate'
import { makeReactivateRequest, updatePerson, updatePersonQualifiedSpec } from 'actions/person'
import { addServerError } from 'actions/serverErrors'
import { useFetch, useModalParams } from 'components/hooks'
import { ENTITY_TYPES, KONTUR_CERTIFICATE_STATUSES, KONTUR_ERRORS_RUS } from 'const'
import moment from 'moment'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'

import { defaultOr } from '@creditclubteam/kit/helpers'
import { Button, Select, Text } from '@creditclubteam/kit/ui-components'
import { ModalDialog } from 'components/common'
import { Grid } from 'components/common/Grid'
import { utils } from 'helpers'

import CertificateBlock from './CertificateBlock'
import SelectSignType from './SelectSignType'
import StatementPreview from './StatementPreview'
import ValidationErrors from './ValidationErrors'

import styles from './KonturCertificate.module.scss'

const RELEASE_CERT_STATEMENT = 'RELEASE_CERT_STATEMENT'

const convertErrors = (error) => ({
	text: KONTUR_ERRORS_RUS[error.code] || error.code,
	message: error.message,
})

const shouldDisableSubmitByKontur = (data, validationResult) => {
	if (validationResult?.subjects.find(({ errors }) => errors.other.length > 0)) return true

	if (!data || !data.form) {
		return false
	}

	if (data.certificate && data.form.state === KONTUR_CERTIFICATE_STATUSES.RELEASED) {
		const { activeFrom, activeTo } = data.certificate

		return moment().isBetween(moment(activeFrom, 'YYYY-MM-DD'), moment(activeTo, 'YYYY-MM-DD'))
	}

	return ![KONTUR_CERTIFICATE_STATUSES.CREATED].includes(data.form.state)
}

/**
 * isShouldDisableButton
 * Функция немного неоднозначная. О том, зачем она нужна, можно прочитать вот тут: https://gitlab.com/creditclubteam/projects/edm/-/issues/10
 *
 * |Условие                                        |1                     |2      |3     |4       |
 * |-----------------------------------------------|----------------------|-------|----------------
 * |signatureProvider - SIGN_ME_CREDIT_CLUB или            всегда да
 *                      SIGN_ME_CONSULT или null
 * |любой, кроме 343                               |нет(он 343)           |да     |нет   |да      |
 * |любая, кроме @sign.me                          |нет(она @sign.me)     |да     |да    |нет     |
 * |                                               |                      |       |      |        |
 * |Действие                                       |Доступна              |Дизейбл|Дизейбл|Дизейбл|
 *
 */
const isShouldDisableButton = ({ entityType, phone, email, signatureProvider }) => {
	if (entityType !== 'PERSON') {
		return false
	}

	if (signatureProvider === 'KONTUR_CREDIT_CLUB') {
		return false
	}

	const isTestingPhone = /^(343|800)/
	const isTestingEmail = /@sign.me$/

	return !(isTestingPhone.test(phone) && isTestingEmail.test(email))
}

const KonturErrorsJSON = (props) => {
	const { error } = props

	return (
		<div>
			<div className={styles.hint}>
				Если вам самостоятельно не удается выяснить причину ошибки, то скопируйте данные кнопкой
				напротив {'"root"'} и отправьте это в Slack разработчикам
			</div>
			<ReactJson
				src={error}
				collapsed={false}
				enableClipboard={true}
				displayDataTypes={false}
				displayObjectSize={false}
			/>
		</div>
	)
}

KonturErrorsJSON.propTypes = {
	error: PropTypes.object,
}

const KonturErrors = (props) => {
	const { errors } = props

	if (!errors.length) return null

	return (
		<div className={styles.konturErrors}>
			<div className={styles.title}>Ошибки</div>
			{errors.filter(Boolean).map((error, i) => (
				<div key={i} className={styles.error}>
					<i className='zmdi zmdi-alert-circle'></i>
					<div>
						<div>{error.text}</div>
						<div>
							<span>Описание:</span> {error.message}
						</div>
					</div>
				</div>
			))}
		</div>
	)
}

KonturErrors.propTypes = {
	errors: PropTypes.array,
}

const KonturCertificate = (props) => {
	const {
		createOrUpdateCertificate,
		entity,
		type,
		addServerError,
		validateSubjectForCertificate,
		createStatement,
		deleteCertificateForm,
		pollingCertificate,
		uploadStatement,
		downloadReleaseCertStatement,
		showActivateBtn = false,
		makeReactivateRequest,
		hasReleaseCertStatement,
		updatePersonQualifiedSpec,
		updatePerson,
	} = props

	const initialSpec = useSelector((state) => state.person.single.spec)
	const [disableSubmit, setDisableSubmit] = useState(true)
	const [validationResult, setValidationResult] = useState(null)
	const [signatureProvider, setSignatureProvider] = useState(initialSpec.qualifiedSignatureProvider)
	const [application, setApplication] = useState(initialSpec.application)
	const { qualifiedSignatureProviders, applicationTypes } = useSelector(
		(state) => state.dictionary.signatures
	)
	const [konturErrors, setKonturErrors] = useState([])
	const [fileToPreview, setFileToPreview] = useState(null)
	const [isKonturErrorsModalShowing, setShowingKonturErrorsModal] = useState(false)
	const [isStatementPreviewModalShowing, setShowingStatementPreviewModal] = useState(false)
	const { isOpen: isEditSignatureProvider, onToggle: handleToggleEditSignatureProvider } =
		useModalParams()
	const { isOpen: isEditApplication, onToggle: handleToggleEditApplication } = useModalParams()
	const {
		isOpen: isModalSelectOpen,
		onClose: onModalSelectClose,
		onOpen: onModalSelectOpen,
	} = useModalParams()

	const handleCancelEdit = (key) => {
		if (key === 'signatureProvider') {
			setSignatureProvider(initialSpec.qualifiedSignatureProvider)
			handleToggleEditSignatureProvider()
		}
		if (key === 'application') {
			setApplication(initialSpec.application)
			handleToggleEditApplication()
		}
	}

	useEffect(() => {
		const konturErrorsFromEntity = entity?.konturInfo?.form?.error?.errros?.map(convertErrors) ?? []

		if (!isEmpty(konturErrorsFromEntity)) {
			setKonturErrors((errors) => [].concat(errors, konturErrorsFromEntity))
		}
	}, [entity])

	useEffect(() => {
		validateSubjectForCertificate(type)
			.then((data) => {
				if (data) setValidationResult(data)

				setDisableSubmit(false)
			})
			.catch((data) => !(data instanceof Error) && setValidationResult(data))
	}, [type, validateSubjectForCertificate])

	const handleOpenKonturErrorsModal = useCallback(() => {
		setShowingKonturErrorsModal(true)
	}, [])

	const handleCloseKonturErrorsModal = useCallback(() => {
		setShowingKonturErrorsModal(false)
	}, [])

	const isShowingCertificateBlock = !!entity?.konturInfo?.form

	const handleSetKonturErrors = useCallback((response, isMounted) => {
		if (isMounted && !isEmpty(utils.take(response, 'errors', []))) {
			setKonturErrors(response.errors.map(convertErrors))
		}
	}, [])

	const { request: handleCreateOrUpdateCertificate, isFetching } = useFetch(
		useCallback(() => {
			setKonturErrors([])

			return createOrUpdateCertificate(entity.id, type)
		}, [createOrUpdateCertificate, entity, type]),
		{
			onFailure: handleSetKonturErrors,
		}
	)

	const handleOpenPreviewStatement = useCallback((file) => {
		setFileToPreview(file)
		setShowingStatementPreviewModal(true)
	}, [])

	const handleClosePreviewStatement = useCallback(() => {
		setFileToPreview(null)
		setShowingStatementPreviewModal(false)
	}, [])

	const handleUploadStatement = useCallback(() => {
		return uploadStatement({
			id: entity.id,
			type,
			file: fileToPreview,
		}).then(pollingCertificate)
	}, [entity.id, type, fileToPreview, uploadStatement, pollingCertificate])

	const handleCreateStatement = useCallback(() => {
		setKonturErrors([])

		return createStatement(entity.id, type)
	}, [entity.id, type, createStatement])

	const handleDeleteCertificateForm = useCallback(() => {
		setKonturErrors([])

		return deleteCertificateForm(entity.id, type)
	}, [entity.id, type, deleteCertificateForm])

	const onSubmitSignType = useCallback(
		async (selectedSignType) => {
			await updatePerson({ signatureProvider: selectedSignType })
			await handleCreateOrUpdateCertificate()
		},
		[updatePerson, handleCreateOrUpdateCertificate]
	)

	const handleUpdateSpec = useCallback(
		async (event) => {
			event.preventDefault()
			setDisableSubmit(true)
			await updatePersonQualifiedSpec({
				personId: entity.id,
				payload: { signatureProvider, application },
			})
			if (isEditApplication) handleToggleEditApplication()
			if (isEditSignatureProvider) handleToggleEditSignatureProvider()
			setDisableSubmit(false)
		},
		[
			updatePersonQualifiedSpec,
			entity.id,
			signatureProvider,
			application,
			isEditApplication,
			handleToggleEditApplication,
			isEditSignatureProvider,
			handleToggleEditSignatureProvider,
		]
	)

	const submitBtnProps = {
		type: 'button',
		size: 'min',
		disabled:
			window.__env__.BUILD_MODE !== 'production' && entity.$$type === ENTITY_TYPES.PERSON
				? isShouldDisableButton({
						entityType: entity.$$type,
						signatureProvider: entity.signatureProvider,
						email: entity?.email || entity?.form?.email,
						phone: entity.phone,
				  }) || shouldDisableSubmitByKontur(utils.take(entity, 'konturInfo'), validationResult)
				: disableSubmit ||
				  isFetching ||
				  shouldDisableSubmitByKontur(utils.take(entity, 'konturInfo'), validationResult),
		children: 'Выпустить подпись',
		onClick:
			entity.$$type === ENTITY_TYPES.PERSON ? onModalSelectOpen : handleCreateOrUpdateCertificate,
	}

	const validationErrorsProps = {
		validationResult,
		type,
	}

	const certificateBlockProps = {
		entity,
		type,
		addServerError,
		hasReleaseCertStatement,
		onCreateStatement: handleCreateStatement,
		onPreview: handleOpenPreviewStatement,
		onSetKonturErrors: handleSetKonturErrors,
		onDeleteCertificateForm: handleDeleteCertificateForm,
		onOpenErrorModal: handleOpenKonturErrorsModal,
		onDownloadReleaseCertStatement: downloadReleaseCertStatement,
	}

	const modalKonturErrorsJSONProps = {
		opened: isKonturErrorsModalShowing,
		large: true,
		title: 'Ошибка валидации',
		onClose: handleCloseKonturErrorsModal,
	}

	const modalStatementPreviewProps = {
		title: 'Предпросмотр документа',
		opened: isStatementPreviewModalShowing,
		onClose: handleClosePreviewStatement,
		className: styles.filePreview,
	}

	const statementPreviewProps = {
		fileToPreview,
		onSubmit: handleUploadStatement,
		onClose: handleClosePreviewStatement,
	}

	const selectSignTypeProps = {
		onSubmit: (selectedSignType) => {
			return onSubmitSignType(selectedSignType)
		},
		onModalSelectClose,
		isShouldDisableButton: (signatureProvider) =>
			isShouldDisableButton({
				signatureProvider,
				email: entity.email || entity?.form?.email,
				phone: entity.phone,
			}),
	}

	const activateBtnProps = {
		type: 'button',
		size: 'min',
		variant: 'outline-red',
		styleOverride: { marginTop: 16 },
		onClick: () => makeReactivateRequest(entity.id),
		children: 'Активировать',
	}

	const isActivateBtnShowed = (signatureProvider) => {
		if (signatureProvider === undefined) return false
		if (
			signatureProvider === 'SIGN_ME_CREDIT_CLUB' ||
			signatureProvider === 'SIGN_ME_CONSULT' ||
			signatureProvider === null
		)
			return true
	}

	const selectSignModalProps = {
		title: 'Выберете провайдера подписи',
		subTitle: 'Если клиент смог установить приложение Sign.me, выберите соответствующий вариант',
		opened: isModalSelectOpen,
		onClose: () => {
			onModalSelectClose()
		},
	}

	const signatureProviderProps = {
		outerWrapStyleOverride: {
			width: 180,
		},
		scale: 'min',
		placeholder: 'Госключ',
		labelPlacing: 'out',
		value: signatureProvider,
		onChange: ({ value }) => setSignatureProvider(value),
		options: qualifiedSignatureProviders.map(({ id, title }) => ({ id, label: title })),
	}

	const applicationProps = {
		outerWrapStyleOverride: {
			width: 250,
		},
		scale: 'min',
		placeholder: 'Выберите приложение',
		labelPlacing: 'out',
		value: application,
		onChange: ({ value }) => setApplication(value),
		options: applicationTypes.map(({ id, title }) => ({ id, label: title })),
	}

	return (
		<>
			<ModalDialog {...modalKonturErrorsJSONProps}>
				<KonturErrorsJSON error={entity?.konturInfo?.form?.error} />
			</ModalDialog>
			<ModalDialog {...modalStatementPreviewProps}>
				<StatementPreview {...statementPreviewProps} />
			</ModalDialog>
			<ModalDialog {...selectSignModalProps}>
				<SelectSignType {...selectSignTypeProps} />
			</ModalDialog>
			<form autoComplete='off' onSubmit={handleUpdateSpec}>
				<Grid.Section gap='xs'>
					<Grid.Row sizes='auto 1fr auto' gap='l' style={{ alignItems: 'center' }}>
						<Text weight='bold'>Провайдер подписания:</Text>
						<Grid.Row gap='l' style={{ alignItems: 'center' }} sizes='auto auto 1fr'>
							{isEditSignatureProvider ? (
								<Select {...signatureProviderProps} />
							) : (
								<Text>
									{defaultOr(
										signatureProviderProps.options.find(({ id }) => id === signatureProvider)
											?.label ?? signatureProvider
									)}
								</Text>
							)}
							{isEditSignatureProvider ? (
								<Grid.Row gap='s'>
									<Button
										size='inline'
										disabled={disableSubmit}
										styleOverride={{ padding: 0 }}
										variant='transparent-blue'
									>
										Сохранить
									</Button>
									<Button
										type='button'
										size='inline'
										onClick={() => handleCancelEdit('signatureProvider')}
										styleOverride={{ padding: 0 }}
										variant='transparent-red'
									>
										Отмена
									</Button>
								</Grid.Row>
							) : (
								<Button
									size='inline'
									type='button'
									onClick={handleToggleEditSignatureProvider}
									styleOverride={{ padding: 0 }}
									variant='transparent-blue'
								>
									Изменить
								</Button>
							)}
						</Grid.Row>
						<Button {...submitBtnProps} />
					</Grid.Row>
					<Grid.Row sizes='auto 1fr auto' gap='l' style={{ alignItems: 'center' }}>
						<Text weight='bold'>Приложение для подписания:</Text>
						<Grid.Row gap='l' style={{ alignItems: 'center' }} sizes='auto auto 1fr'>
							{isEditApplication ? (
								<Select {...applicationProps} />
							) : (
								<Text>
									{defaultOr(
										applicationProps.options.find(({ id }) => id === application)?.label ??
											application
									)}
								</Text>
							)}
							{isEditApplication ? (
								<Grid.Row gap='s'>
									<Button
										size='inline'
										disabled={disableSubmit}
										styleOverride={{ padding: 0 }}
										variant='transparent-blue'
									>
										Сохранить
									</Button>
									<Button
										type='button'
										size='inline'
										onClick={() => handleCancelEdit('application')}
										styleOverride={{ padding: 0 }}
										variant='transparent-red'
									>
										Отмена
									</Button>
								</Grid.Row>
							) : (
								<Button
									size='inline'
									type='button'
									onClick={handleToggleEditApplication}
									styleOverride={{ padding: 0 }}
									variant='transparent-blue'
								>
									Изменить
								</Button>
							)}
						</Grid.Row>
					</Grid.Row>
				</Grid.Section>
			</form>
			{showActivateBtn && isActivateBtnShowed(entity.signatureProvider) && (
				<Button {...activateBtnProps} />
			)}
			{window.__env__.BUILD_MODE !== 'production' &&
				entity.$$type === ENTITY_TYPES.PERSON &&
				isShouldDisableButton({
					entityType: entity.$$type,
					signatureProvider: entity.signatureProvider,
					email: entity.email || entity?.form?.email,
					phone: entity.phone,
				}) && (
					<div className={styles.wrapper}>
						<div className={styles.title}>Требования для тестовой подписи</div>
						<div>
							Телефон должен начинаться с кода города 343 или 800, почта должна быть в домене
							sign.me.
						</div>
					</div>
				)}
			{isShowingCertificateBlock && <CertificateBlock {...certificateBlockProps} />}
			{!!validationResult && <ValidationErrors {...validationErrorsProps} />}
			{!isEmpty(konturErrors) && <KonturErrors errors={konturErrors} />}
		</>
	)
}

KonturCertificate.propTypes = {
	entity: PropTypes.object,
	type: PropTypes.string,
	hasReleaseCertStatement: PropTypes.bool,

	uploadStatement: PropTypes.func,
	createStatement: PropTypes.func,
	pollingCertificate: PropTypes.func,
	updatePersonQualifiedSpec: PropTypes.func,
	showActivateBtn: PropTypes.bool,
	validateSubjectForCertificate: PropTypes.func,
	createOrUpdateCertificate: PropTypes.func,
	deleteCertificateForm: PropTypes.func,
	addServerError: PropTypes.func,
}

const mapStateToProps = (state) => ({
	hasReleaseCertStatement: !isEmpty(
		state.document.files.data.filter((e) => e.categoryId === RELEASE_CERT_STATEMENT)
	),
})

const mapDispatchToProps = {
	updateCertificateStatus,
	createOrUpdateCertificate,
	updatePersonQualifiedSpec,
	createStatement,
	uploadStatement,
	makeReactivateRequest,
	addServerError,
	deleteCertificateForm,
	validateSubjectForCertificate,
	downloadReleaseCertStatement,
	updatePerson,
}

export default connect(mapStateToProps, mapDispatchToProps)(KonturCertificate)
